import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { graphql } from 'gatsby';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

interface PublicationsBib {
  key: string;
  authors: string[];
  date: string;
  file: {
    publicURL: string;
  };
  title: string;
}

function groupBy(xs, f) {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

const formatText = (text: string) =>
  text
    .replace('\\"u', 'ü')
    .replace('\\"o', 'ö')
    .replace('\\"a', 'ä')
    .replace('"u', 'ü')
    .replace('"o', 'ö')
    .replace('"a', 'ä');

const SinglePublication: React.FC = (props: PublicationsBib) => {
  const node = props.node;
  const hasFileAttached = node.file && node.file.publicURL;
  let publicationInfo = '';
  if (node.entry_type === 'inproceedings') {
    publicationInfo = '. In: ' + formatText(node.booktitle);
  }
  if (node.entry_type === 'incollection') {
    publicationInfo = '. In: ' + formatText(node.booktitle) + (node.volume ? ' (' + node.volume + ')' : '') ;
  } else if (node.entry_type === 'article') {
    publicationInfo = '. ' + formatText(node.journal);
    if (node.number) {
      publicationInfo += ' ' + node.number;
    }
  } else if (node.entry_type === 'phdthesis') {
    publicationInfo = ' (Phd-Thesis)';
  } else if (node.entry_type === 'inbook') {
  } else if (node.entry_type === 'book') {
  } else if (node.entry_type === 'book') {
  }
  const content = (
    <>
      {' '}
      {node.authors.map((author: string, index: number) => (
        <span key={author}>
          {node.authors.length === index + 1 ? (
            <>{node.authors.length > 1 ? <>und {author}</> : <>{author}</>}</>
          ) : node.authors.length - 2 === index ? (
            <>{author}</>
          ) : (
            <>{author},</>
          )}{' '}
        </span>
      ))}
      ({node.date}){': '} <i>{formatText(node.title)}</i>
      {publicationInfo !== '' && publicationInfo}
      {node.publisher && ', ' + node.publisher}
      {node.venue && ', ' + node.venue}
      {node.pages && ', S. ' + node.pages.replace('--', '\u2013')}.
    </>
  );
  if (hasFileAttached) {
    return (
      <p key={node.key}>
        <a href={node.file.publicURL}>{content}</a>
      </p>
    );
  }
  else if (node.url) {
    return (
      <p key={node.key}>
        <a href={node.url}>{content}</a>
      </p>
    );
  }
  return <p key={node.key}>{content}</p>;
};

const Publications: React.FC = (props) => {
  let publications: PublicationsBib[] = props.data.allReference.edges;
  console.log(publications);
  publications.sort((a, b) => Date.parse(b.node.date) - Date.parse(a.node.date));
  const dictionary = groupBy(publications, (p) => p.node.date);
  console.log(dictionary);
  return (
    <IndexLayout>
      <Helmet>
        <title>Publikationen</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <SiteNav />
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader>
              <PostFullTitle>Publikationen</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                {Object.keys(dictionary)
                  .reverse()
                  .map((key) => (
                    <>
                      <h2 style={{ paddingTop: '0.5em' }}>{key}</h2>
                      <>{dictionary[key].map((node) => SinglePublication(node))}</>
                    </>
                  ))}
              </div>
            </PostFullContent>
          </article>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Publications;

export const pageQuery = graphql`
  {
    allReference {
      edges {
        node {
          key
          entry_type
          title
          volume
          journal
          number
          booktitle
          authors
          pages
          venue
          url
          date
          publisher
          file {
            publicURL
          }
        }
      }
    }
  }
`;
